import {
    StyledArrowsBlock,
    StyledChooseCurrencyBlock,
    StyledCurrencyBlock,
    StyledCurrencyHeaderBlock,
    StyledCurrencyHeaderTitle,
    StyledCurrencyListBlock,
    StyledExchangeArrow,
    StyledExchangeDialog,
    StyledExchangeWrapper,
    StyledSearchWrapper
} from "./styledExchangeModal";
import {useTranslation} from "react-i18next";
import {StyledPaymentMethodsIcons} from "../../../styles/StyledPaymentMethodsIcons";
import React, {useCallback, useEffect, useRef, useState} from "react";
import exchangeArrow from "../../../../assets/images/leftSidebar/reverse-icon.svg";
import InputGroup from "../../../elements/inputGroup/InputGroup";
import BetAction from "../../../elements/betAction/BetAction";
import ReCaptcha from "../../../elements/reCaptcha/ReCaptcha";
import {StyledButton} from "../../../styles/styledButton";
import {StyledWrapperPaymentItem, StyledPaymentMethodItem} from "../../../profileActionsWindow/styledProfileActions";
import Big from "big.js";
import {formatNumber} from "../../../../utils/formatNumber";
import {useBetween} from "use-between";
import BalanceStates from "../../../games/BalanceStates";
import http from "../../../../http";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import {closableNotification} from "../../notification/ClosableNotification";
import ConfirmExchangeModal from "../ConfirmExchangeModal/ConfirmExchangeModal";
import LoadButton from "../../spinner/ButtonSpinner";

function ExchangeModal({visible, setVisible, user}) {
    const {t} = useTranslation("siteOptions");
    const {t: errorsT} = useTranslation("errors");

    const {paymentMethods} = useBetween(BalanceStates);

    const [isRevers, setIsRevers] = useState(false);

    const [payPaymentMethods, setPayPaymentMethods] = useState(paymentMethods.filter(method => method?.currency?.asset !== "LUCKY"));
    const [receivePaymentMethods, setReceivePaymentMethods] = useState(paymentMethods.filter(method => method?.currency?.asset !== "LUCKY"));

    const [payInputValue, setPayInputValue] = useState("0.00000000");
    const [receiveInputValue, setReceiveInputValue] = useState("0.00000000");

    const [paySearchValue, setPaySearchValue] = useState("");
    const [receiveSearchValue, setReceiveSearchValue] = useState("");

    const [apiExchangeRate, setApiExchangeRate] = useState(new Big(1));
    const [maxExchangeSum, setMaxExchangeSum] = useState(new Big(0));
    const [minExchangeSum, setMinExchangeSum] = useState(new Big(0));

    const [exchangeData, setExchangeData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const recaptchaRef = useRef(null);

    const [paySelectedPaymentMethod, setPaySelectedPaymentMethod] = useState((localStorage.getItem("paymentMethod") && localStorage.getItem("paymentMethod") !== "undefined") ?
        JSON.parse(localStorage.getItem("paymentMethod")) :
        payPaymentMethods[0]);
    const [receiveSelectedPaymentMethod, setReceiveSelectedPaymentMethod] = useState((localStorage.getItem("paymentMethod") && localStorage.getItem("paymentMethod") !== "undefined") ?
        JSON.parse(localStorage.getItem("paymentMethod")) :
        receivePaymentMethods[0]);

    const calculateExchangeRate = (fromCurrency, toCurrency) => {
        if (apiExchangeRate && !apiExchangeRate.eq(new Big(1))) return apiExchangeRate;
        if (!fromCurrency?.currency?.rate || !toCurrency?.currency?.rate) return new Big(1);
        return new Big(fromCurrency.currency.rate).div(toCurrency.currency.rate);
    };

    const handlePayInput = useCallback((value) => {
        const filteredValue = value.replace(/[^0-9.]/g, '');
        const numValue = filteredValue.replace(/,/g, '.');

        if (!isNaN(numValue) && (numValue.match(/\./g) || []).length <= 1) {
            let [intPart, decimalPart] = numValue.split('.');
            if (decimalPart && decimalPart.length > 8) {
                decimalPart = decimalPart.substring(0, 8);
            }

            const formattedPayValue = decimalPart !== undefined ? [intPart, decimalPart].join('.') : intPart;

            setPayInputValue(formattedPayValue);
            if (receiveSelectedPaymentMethod.currency.asset === "LUCKY" || paySelectedPaymentMethod.currency.asset === "LUCKY") return setReceiveInputValue("0.00000000")

            const exchangeRate = calculateExchangeRate(paySelectedPaymentMethod, receiveSelectedPaymentMethod);

            const newReceiveValue = new Big(formattedPayValue ? formattedPayValue : 0).times(exchangeRate);
            setReceiveInputValue(formatNumber(newReceiveValue.toFixed(8)));
        }
    }, [paySelectedPaymentMethod, receiveSelectedPaymentMethod]);

    const handleReceiveInput = useCallback((value) => {
        const filteredValue = value.replace(/[^0-9.]/g, '');
        const numValue = filteredValue.replace(/,/g, '.');

        if (!isNaN(numValue) && (numValue.match(/\./g) || []).length <= 1) {
            let [intPart, decimalPart] = numValue.split('.');
            if (decimalPart && decimalPart.length > 8) {
                decimalPart = decimalPart.substring(0, 8);
            }

            const formattedReceiveValue = decimalPart !== undefined ? [intPart, decimalPart].join('.') : intPart;

            setReceiveInputValue(formattedReceiveValue);
            if (receiveSelectedPaymentMethod.currency.asset === "LUCKY" || paySelectedPaymentMethod.currency.asset === "LUCKY") return setPayInputValue("0.00000000")

            const exchangeRate = calculateExchangeRate(paySelectedPaymentMethod, receiveSelectedPaymentMethod);

            const newPayValue = Big(formattedReceiveValue ? formattedReceiveValue : 0.00000000).div(exchangeRate);
            if (!formattedReceiveValue) setReceiveInputValue("");
            setPayInputValue(value ? formatNumber(newPayValue.toFixed(8)) : "0.00000000");
        }
    }, [paySelectedPaymentMethod, receiveSelectedPaymentMethod]);

    const handleItemClick = useCallback((value, variant) => {
        if (variant === "pay") {
            setPaySelectedPaymentMethod(value);
        } else if (variant === "receive") {
            setReceiveSelectedPaymentMethod(value);
        }
    }, []);

    const handleSearch = (e) => {
        const {value, name} = e.target;
        let resultSort = paymentMethods.filter(method => method?.currency?.asset !== "LUCKY").filter((item) => item.currency.name.toLowerCase().includes(value.toLowerCase()) || item.currency.asset.toLowerCase().includes(value.toLowerCase()));
        name === "paySearch" ? setPayPaymentMethods(resultSort) : setReceivePaymentMethods(resultSort);
        name === "paySearch" ? setPaySearchValue(value) : setReceiveSearchValue(value);
    };

    function clearSearch(searchName) {
        searchName === "paySearch" ? setPayPaymentMethods(paymentMethods) : setReceivePaymentMethods(paymentMethods);
        searchName === "paySearch" ? setPaySearchValue("") : setReceiveSearchValue("");
    }

    useEffect(() => {
        if (paySelectedPaymentMethod && receiveSelectedPaymentMethod) {
            const exchangeRate = calculateExchangeRate(paySelectedPaymentMethod, receiveSelectedPaymentMethod);
            const newReceiveValue = Big(payInputValue ? payInputValue : 0.00000000).times(exchangeRate);
            if (!payInputValue) setPayInputValue("0.00000000");
            setReceiveInputValue(formatNumber(newReceiveValue.toFixed(8)));
        }
    }, [paySelectedPaymentMethod, receiveSelectedPaymentMethod, apiExchangeRate]);

    useEffect(() => {
        if (!paymentMethods) return;
        const filteredMethods = paymentMethods.filter(method => method?.currency?.asset !== "LUCKY");
        setPayPaymentMethods(filteredMethods);
        setReceivePaymentMethods(filteredMethods);
        setPaySelectedPaymentMethod(filteredMethods[0]);
        setReceiveSelectedPaymentMethod(filteredMethods[0]);
    }, [paymentMethods?.length])

    const handleReverse = () => {
        setPaySelectedPaymentMethod(receiveSelectedPaymentMethod);
        setReceiveSelectedPaymentMethod(paySelectedPaymentMethod);
        setPayInputValue(receiveInputValue);
        setReceiveInputValue(payInputValue);
        setIsRevers(!isRevers);
    };

    const getExchangeRateReq = (fromAsset, toAsset) => {
        if (!fromAsset || !toAsset || fromAsset === "LUCKY" || toAsset === "LUCKY" || fromAsset === toAsset) return setApiExchangeRate(new Big(1));
        http.get(`${process.env.REACT_APP_EXCHANGE_LINK}/api/swap/currency?from=${fromAsset}&to=${toAsset}`, {...userAuthenticationConfig()})
            .then(response => {
                setApiExchangeRate(response.data.price);
                setMaxExchangeSum(new Big(response?.data?.available_max));
                setMinExchangeSum(new Big(response?.data?.available_min))
            })
            .catch(error => {
                closableNotification(errorsT(error?.response?.data?.error_message), "error");
            });
    }

    const reqExchange = () => {
        const data = {
            "from": paySelectedPaymentMethod?.currency?.asset,
            "to": receiveSelectedPaymentMethod?.currency?.asset,
            "amount": (new Big(payInputValue)).toNumber(),
        }
        const captchaToken = recaptchaRef.current.getValue();
        if (!captchaToken) return closableNotification(errorsT("Failed authentication, suspicious activity"), "error");
        recaptchaRef.current.reset();
        setIsLoading(true);
        http.post(`${process.env.REACT_APP_EXCHANGE_LINK}/api/swap/estimate`, data, {...userAuthenticationConfig(false)})
            .then(response => {
                setExchangeData(response?.data);
                setIsLoading(false);
            })
            .catch(error => {
                closableNotification(errorsT(error?.response?.data?.error_message), "error");
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getExchangeRateReq(paySelectedPaymentMethod?.currency?.asset, receiveSelectedPaymentMethod?.currency?.asset);
    }, [paySelectedPaymentMethod, receiveSelectedPaymentMethod]);


    return (
        <>
            {exchangeData ? (<ConfirmExchangeModal exchangeData={exchangeData} setExchangeData={setExchangeData} setVisibleExchangeModal={setVisible}/>) : (
                <StyledExchangeDialog
                    visible={visible}
                    wrapClassName="default-modal-wrapper"
                    onClose={() => setVisible(false)}
                    animation="zoom"
                    maskAnimation="fade"
                    title={t("exchangeTitleModal")}
                    forceRender={false}
                    className="default-modal default-modal-bonus"
                    style={{margin: "auto"}}
                    destroyOnClose={true}
                    footer={!isLoading ? <StyledButton color="neutral" width="100" onClick={reqExchange}
                                                       disabled={(paySelectedPaymentMethod?.currency?.asset === receiveSelectedPaymentMethod?.currency?.asset) || (paySelectedPaymentMethod.balances?.amount <= 0) || (paySelectedPaymentMethod.balances?.amount < payInputValue) || (0.00000001 > payInputValue) || (receiveInputValue <= 0) || (payInputValue <= 0) || ((paySelectedPaymentMethod?.currency?.asset === "LUCKY" || receiveSelectedPaymentMethod?.currency?.asset === "LUCKY"))}>
                        {t("exchange")} {paySelectedPaymentMethod?.currency?.asset} {t("to")} {receiveSelectedPaymentMethod?.currency?.asset}
                    </StyledButton> : <LoadButton
                        text={`${t("exchange")} ${paySelectedPaymentMethod?.currency?.asset} ${t("to")} ${receiveSelectedPaymentMethod?.currency?.asset}`}
                        color="neutral"
                        type="submit"
                        style={{width: '100%'}}
                        disabled={true}
                    />}
                >
                    <StyledExchangeWrapper>
                        <StyledWrapperPaymentItem className="payment-methods">
                            <StyledCurrencyBlock>
                                <StyledChooseCurrencyBlock>
                                    <StyledCurrencyHeaderBlock>
                                        <StyledCurrencyHeaderTitle>{t("youGive")}</StyledCurrencyHeaderTitle>
                                        <StyledSearchWrapper className="coin-search">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={`coin-search__input ${paySearchValue ? "active" : ""}`}
                                                placeholder={`${t("find")}...`}
                                                value={paySearchValue}
                                                onChange={handleSearch}
                                                name="paySearch"
                                                onKeyUp={handleSearch}
                                            />
                                            <div
                                                className={`coin-search__clean ${paySearchValue ? "active" : ""}`}
                                                onClick={() => clearSearch("paySearch")}
                                            >✕
                                            </div>
                                        </StyledSearchWrapper>
                                    </StyledCurrencyHeaderBlock>
                                    <StyledCurrencyListBlock>
                                        {paymentMethods.length !== 0 ? (payPaymentMethods.length !== 0 ? payPaymentMethods.map((value, key) => (
                                                <StyledPaymentMethodItem
                                                    key={`${key}-pay`}
                                                    onClick={() => handleItemClick(value, "pay")}
                                                    active={paySelectedPaymentMethod?.currency?.asset === value?.currency?.asset}
                                                    className={`payment-method-item ${paySelectedPaymentMethod?.currency?.asset === value?.currency?.asset ? "active" : ""}`}
                                                >
                                                    <StyledPaymentMethodsIcons
                                                        className={`payment-method-${value?.currency?.asset}`}/>
                                                </StyledPaymentMethodItem>
                                            )) : <StyledPaymentMethodItem>{t("nthFound")}</StyledPaymentMethodItem>) :
                                            <StyledPaymentMethodItem>{t("loading")}</StyledPaymentMethodItem>}
                                    </StyledCurrencyListBlock>
                                    <InputGroup
                                        autocomplete="off"
                                        id="amount"
                                        type="text"
                                        label={`${paySelectedPaymentMethod?.currency?.name} ${t("amount")}`}
                                        name="payAmount"
                                        className={"pay-amount"}
                                        value={payInputValue}
                                        handleInput={handlePayInput}
                                        betAction={
                                            <BetAction value={payInputValue}
                                                       setValue={handlePayInput}
                                                       min={minExchangeSum.toString()}
                                                       max={parseFloat(paySelectedPaymentMethod?.balances?.amount ?? 0) < maxExchangeSum.toNumber() ? parseFloat(paySelectedPaymentMethod?.balances?.amount ?? 0) : maxExchangeSum.toNumber()}
                                            />}
                                        hintsWithMax={paySelectedPaymentMethod ? {
                                            ...paySelectedPaymentMethod,
                                            fee: {
                                                ...paySelectedPaymentMethod?.fee,
                                                min: minExchangeSum.toString(),
                                                max: maxExchangeSum.toFixed(8)
                                            }
                                        } : null}
                                        required
                                    />
                                </StyledChooseCurrencyBlock>
                                <StyledArrowsBlock>
                                    <StyledExchangeArrow isRevers={isRevers} src={exchangeArrow} alt="Arrows"
                                                         className="arrow"
                                                         onClick={handleReverse}/>
                                </StyledArrowsBlock>
                                <StyledChooseCurrencyBlock>
                                    <StyledCurrencyHeaderBlock>
                                        <StyledCurrencyHeaderTitle>{t("youGet")}</StyledCurrencyHeaderTitle>
                                        <StyledSearchWrapper className="coin-search">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={`coin-search__input ${receiveSearchValue ? "active" : ""}`}
                                                placeholder={`${t("find")}...`}
                                                value={receiveSearchValue}
                                                onChange={handleSearch}
                                                name="receiveSearch"
                                                onKeyUp={handleSearch}
                                            />
                                            <div
                                                className={`coin-search__clean ${receiveSearchValue ? "active" : ""}`}
                                                onClick={() => clearSearch("receiveSearch")}
                                            >✕
                                            </div>
                                        </StyledSearchWrapper>
                                    </StyledCurrencyHeaderBlock>
                                    <StyledCurrencyListBlock>
                                        {paymentMethods.length !== 0 ? (receivePaymentMethods.length !== 0 ? receivePaymentMethods.map((value, key) => (
                                                <StyledPaymentMethodItem
                                                    key={`${key}-receive`}
                                                    onClick={() => handleItemClick(value, "receive")}
                                                    active={receiveSelectedPaymentMethod?.currency?.asset === value?.currency?.asset}
                                                    className={`payment-method-item ${receiveSelectedPaymentMethod?.currency?.asset === value?.currency?.asset ? "active" : ""}`}
                                                >
                                                    <StyledPaymentMethodsIcons
                                                        className={`payment-method-${value?.currency?.asset}`}/>
                                                </StyledPaymentMethodItem>
                                            )) : <StyledPaymentMethodItem>{t("nthFound")}</StyledPaymentMethodItem>) :
                                            <StyledPaymentMethodItem>{t("loading")}</StyledPaymentMethodItem>}
                                    </StyledCurrencyListBlock>
                                    <InputGroup
                                        autocomplete="off"
                                        id="amount"
                                        type="text"
                                        label={`${receiveSelectedPaymentMethod?.currency?.name} ${t("amount")}`}
                                        name="amount"
                                        betAction={(paySelectedPaymentMethod ?
                                            <BetAction value={receiveInputValue} isExchange
                                                       setValue={handleReceiveInput}
                                                       min={minExchangeSum.toString()}
                                                       max={(
                                                           (Big(paySelectedPaymentMethod?.balances?.amount ?? 0).lt(maxExchangeSum)
                                                                   ? Big(paySelectedPaymentMethod?.balances?.amount ?? 0)
                                                                   : maxExchangeSum
                                                           ).times(calculateExchangeRate(paySelectedPaymentMethod, receiveSelectedPaymentMethod))
                                                       ).toFixed(8)}                                            /> : null)}
                                        value={receiveInputValue}
                                        handleInput={handleReceiveInput}
                                        required
                                    />
                                </StyledChooseCurrencyBlock>
                            </StyledCurrencyBlock>
                        </StyledWrapperPaymentItem>
                        <ReCaptcha recaptchaRef={recaptchaRef}/>
                    </StyledExchangeWrapper>
                </StyledExchangeDialog>)
            }
        </>
    )
}

export default ExchangeModal;