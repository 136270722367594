import Dialog from "rc-dialog";
import React, { useContext, useEffect, useState } from "react";
import { StyledBodyTabs, StyledMenuNav, StyledTopTabs, StyledModalLoader } from "./styledFaucetsModal";
import ModeratorFaucet from "../ModeratorFaucet/ModeratorFaucet";
import VipFaucet from "../VipFaucet/VipFaucet";
import LegendFaucet from "../LegendFaucet/LegendFaucet";
import { AppContext } from "../../../../App";
import { privileges, roles } from "../../../../utils/consts";

function FaucetsModal({ faucetsStatusData, setVisible, visible, getFaucetsStatusReq, t, userPrivileges }) {
    const [activeKeyTab, setActiveKeyTab] = useState("");
    const [activeCurrency, setActiveCurrency] = useState("Polygon");
    const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));
    const [autoSelected, setAutoSelected] = useState(false);

    const { user } = useContext(AppContext);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(Math.floor(Date.now() / 1000));
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const tabs = [];
    const isModerator = user?.roles?.[0] === roles.MODERATOR || user?.roles?.[0] === roles.ADMIN;
    const isVip = userPrivileges?.some((p) => p?.type === privileges.VIP);
    const isLegend = userPrivileges?.some((p) => p?.type === privileges.LEGEND);

    if (isModerator) {
        tabs.push({
            name: "moderator",
            container: ModeratorFaucet,
        });
    }
    if (isVip) {
        tabs.push({
            name: "vip",
            container: VipFaucet,
        });
    }
    if (isLegend) {
        tabs.push({
            name: "legend",
            container: LegendFaucet,
        });
    }

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs > 0 ? String(hrs).padStart(2, "0") + ":" : ""}${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    useEffect(() => {
        if (tabs.length === 0 || faucetsStatusData.length === 0 || autoSelected) return;

        let defaultTabType = "";

        for (let tab of tabs) {
            const status = faucetsStatusData.find((s) => s.type === tab.name);
            if (status) {
                const remaining = status.next_bonus_time - currentTime;
                if (remaining < 4) {
                    defaultTabType = tab.name;
                    break;
                }
            }
        }

        if (!defaultTabType) {
            let minRemaining = Infinity;
            for (let tab of tabs) {
                const status = faucetsStatusData.find((s) => s.type === tab.name);
                if (status) {
                    const remaining = status.next_bonus_time - currentTime;
                    if (remaining < minRemaining) {
                        minRemaining = remaining;
                        defaultTabType = tab.name;
                    }
                }
            }
        }
        if (!defaultTabType && tabs.length > 0) {
            defaultTabType = tabs[0].name;
        }
        setActiveKeyTab(defaultTabType);
        setAutoSelected(true);
    }, [tabs, faucetsStatusData, currentTime, autoSelected]);

    useEffect(() => {
        if (visible) getFaucetsStatusReq();

        return () => {
            setActiveKeyTab("");
            setActiveCurrency("Polygon");
            setCurrentTime(Math.floor(Date.now() / 1000));
            setAutoSelected(false);
        }
    }, [visible]);

    const getTabLabel = (tab) => {
        let label = t(tab.name);
        const status = faucetsStatusData.find((s) => s.type === tab.name);
        if (status) {
            const remaining = status.next_bonus_time - currentTime;
            if (remaining > 3) {
                label += ` (${formatTime(remaining)})`;
            }
        }
        return label;
    };

    const getTabClassList = (tab) => {
        const classList = ["tabs-titles__item"];
        if (activeKeyTab === tab.name) {
            classList.push("active");
        }
        return classList.join(" ");
    };

    return (
        <Dialog
            visible={visible}
            wrapClassName="default-modal-wrapper"
            onClose={() => setVisible(false)}
            animation="zoom"
            maskAnimation="fade"
            title={t("moderatorBonusModalTitle", { currency: activeCurrency })}
            forceRender={false}
            destroyOnClose={true}
            className="default-modal"
        >
            <StyledMenuNav>
                {tabs.length > 1 && <StyledTopTabs>
                    <div className="tabs-titles">
                        <div className="all-tokens">
                            {tabs.map((tab, key) => (
                                <div
                                    key={key}
                                    className={getTabClassList(tab)}
                                    onClick={() => setActiveKeyTab(tab.name)}
                                >
                                    {getTabLabel(tab)}
                                </div>
                            ))}
                        </div>
                    </div>
                </StyledTopTabs>}
                <StyledBodyTabs>
                    {autoSelected ? tabs.map((tab, key) => {
                        const TabContainer = tab.container;
                        const status = faucetsStatusData.find((s) => s.type === tab.name);
                        const bonusAvailable = status ? (status.next_bonus_time - currentTime) < 4 : false;
                        const remaining = status ? status.next_bonus_time - currentTime : 0;
                        return (
                            <TabContainer
                                visible={tab.name === activeKeyTab}
                                setVisible={setVisible}
                                key={key}
                                setActiveCurrency={setActiveCurrency}
                                t={t}
                                getFaucetsStatusReq={getFaucetsStatusReq}
                                bonusAvailable={bonusAvailable}
                                remainingTime={remaining}
                            />
                        );
                    }) : <StyledModalLoader />}
                </StyledBodyTabs>
            </StyledMenuNav>
        </Dialog>
    );
}

export default FaucetsModal;