import styled, {css} from "styled-components";
import Dialog from "rc-dialog";
import zoom from "../../../../assets/images/search-icon.svg";

export const StyledExchangeDialog = styled(Dialog)`
    max-width: 900px !important;
    
    .payment-methods {
        margin-bottom: -10px;
        padding-bottom: 0;
    }
    
    .rc-dialog-footer button {
        margin-top: 0;
    }
    
    .recaptcha-wrapper__content {
        margin-bottom: -25px;
    }

    @media screen and (max-width: 480px) {
        .payment-methods {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
`;

export const StyledExchangeWrapper = styled.div`
    position: relative;
    
    .pay-amount > div:last-child {
        flex-direction: column;
    }
    
    .blocked-info-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        position: relative;
        font-size: 18px;
        padding: 7px 5px;

        span {
            color: ${({theme}) => theme.bonusGame.textSec};
        }
    }

    .claim-info-block {
        h3 {
            text-transform: uppercase;
        }

        span {
            color: ${({theme}) => theme.bonusGame.textSec};
        }

        p {
            padding: 15px 0;
            color: ${({theme}) => theme.bonusGame.textSec};

            a, .link {
                color: ${({theme}) => theme.bonusGame.link};
                cursor: pointer;
            }

            a:hover, .link:hover {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }

        .info-bonus {
            padding-top: 0;
        }
    }

    .bonus-table-header {
        color: ${({theme}) => theme.bonusGame.textMain};
    }

    .bonus-row-table, .bonus-row-table * {
        background-color: ${({theme}) => theme.bonusGame.tableItem};
        color: ${({theme}) => theme.bonusGame.textSec};
        font-size: 14px;
    }

    .bonus-row-table a {
        border-bottom: ${({theme}) => theme.bonusGame.textSec} dashed 1px;
        transition: all 0.3s ease !important;
    }

    .bonus-row-table a .nickname {
        transition: all 0.3s ease !important;
    }

    .bonus-row-table a:hover {
        border-bottom: ${({theme}) => theme.accountSettingLink.color} dashed 1px;
    }

    .bonus-row-table a:hover .nickname {
        color: ${({theme}) => theme.accountSettingLink.color};
    }

    .bonus-modal-block {
        background: ${({theme}) => theme.bonusGame.blockBg};
    }

    @media screen and (max-width: 782px) {
        width: 564px;
        margin-left: auto;
        margin-right: auto;
    }
    
    @media screen and (max-width: 730px) {
        width: 517px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 680px) {
        width: 470px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 610px) {
        width: 423px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 550px) {
        width: 376px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 495px) {
        width: 329px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 480px) {
        width: 376px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 445px) {
        width: 329px;
        margin-left: auto;
        margin-right: auto;
    }

    @media screen and (max-width: 385px) {
        width: 282px;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const StyledCurrencyBlock = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    
    @media screen and (max-width: 782px) {
        flex-direction: column;
        align-items: center;
    }
`

export const StyledChooseCurrencyBlock = styled.div`
    display: flex;
    width: 376px;
    flex-wrap: wrap;
    align-content: flex-start;
    
    & > .input-group {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 0;
    }

    @media screen and (max-width: 1025px) {
        width: 329px;
    }
    
    @media screen and (max-width: 900px) {
        width: 282px;
    }
    @media screen and (max-width: 782px) {
        width: 100%;
    }
`

export const StyledArrowsBlock = styled.div`
    position: relative;
    width: 10%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0.3;
    margin-top: 58px;
    margin-bottom: 136px;

    @media screen and (max-width: 900px) {
        margin-bottom: 136px;
    }
    
    @media screen and (max-width: 782px) {
        width: auto;
        height: 70px;
        margin-top: 15px;
        margin-bottom: -5px;
    }
`;

export const StyledExchangeArrow = styled.img`
    width: 60%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    transition: 0.3s ease transform;
    cursor: pointer;
    transform: ${({isRevers}) => isRevers ? "rotate(180deg)" : "rotate(0)"};

    @media screen and (max-width: 782px) {
        transform: ${({isRevers}) => isRevers ? "rotate(270deg)" : "rotate(90deg)"};
    }
`

export const StyledCurrencyHeaderBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #058bd8;
    font-size: 21px;
    height: fit-content;
    width: 100%;
    & .coin-search {
        position: relative;
    }
`

export const StyledCurrencyHeaderTitle = styled.div`

`

export const StyledSearchWrapper = styled.div`
  padding: 15px 0 8px;
  width: 141px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.elementBg};
  z-index: 1;

  .coin-search__input {
    border-radius: 3px;
    background-image: url(${zoom});
    background-repeat: no-repeat;
      text-transform: capitalize;
    
    font-family: inherit;
    
    position: relative;
    z-index: 2;
    //transition: all 0.15s ease-in-out;

    background-color: transparent;
    height: 35px;
    padding: 7px 30px 7px 9px;
    border: 1px solid ${({ theme }) => theme.paymentMethodSearch.borderColor};
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2);
    opacity: ${({ theme }) => theme.paymentMethodSearch.opacity};
    color: ${({ theme }) => theme.paymentMethodSearch.color};
    background-position: top 10px right 13px;
    background-size: 14px;
    font-size: 14px;
    width: 100%;

    &:focus {
      border-bottom-color: #058bd8;
      ~ .coin-config__button {
        display: none;
      }
    }

    &.active {
      border-bottom-color: #058bd8;
      ~ .coin-config__button {
        display: none;
      }
    }
  }

  .coin-search__clean {
    color: #777777;
    width: 30px;
    height: 30px;
    position: absolute;
    
    &:hover {
      color: ${({ theme }) => theme.historyLinkHover};
    }
    
    font-weight: 500;
    text-align: center;
    line-height: 30px;
    display: none;
    cursor: pointer;
    transition: all 0.15s ease-in-out;

    ${({ isNavigation }) => isNavigation ?
    css`
        right: 15px;
        top: 10px;
        background-color: transparent;
      ` :
    css`
        right: 2px;
        top: 17px;
        background-color: ${({ theme }) => theme.leftSidebar.blockBgColor};
      `};

    &.active {
      display: block;
      z-index: 3;
    }
  }

  .coin-config__button {
    ${({ theme }) => theme.paymentMethodConfig};
    background-size: 18px;
    align-self: center;
    margin: 1px 1px 0 0;
    width: 42px;
    height: 33px;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledCurrencyListBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
`